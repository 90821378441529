import { Typography } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import * as React from 'react';
import { BaseLayout } from '@/components/layouts/base_layout';

const IndexPage: React.FC = () => (
  <BaseLayout pageTitle="Home Page">
    <Typography variant="h3" color="primary">
      Welcome to my Gatsby site!
    </Typography>
    <Link to="/about">About</Link>
  </BaseLayout>
);

export default IndexPage;
